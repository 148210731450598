import React from "react";
import { Helmet } from "react-helmet-async";

export default function SEO({
  title = "Default Title",
  description = "Default description for the site.",
  name = "Apinun Saartiam",
  image = "https://apinun.dev/default-image.jpg",
  type = "website",
}) {
  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>{title}</title>
      <link rel="canonical" href="https://apinun.dev/" />
      <meta charSet="utf-8" />
      <meta name="author" content={name} />
      <meta
        name="keywords"
        content="apinun saartiam,Apinun Saartiam,Apinun Dev,apinun dev,"
      />
      <meta name="robots" content="index, follow" />
      <meta name="description" content={description} />
      {/* End standard metadata tags */}

      {/* Open Graph tags */}
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content="https://apinun.dev/" />
      {/* End Open Graph tags */}

      {/* Twitter tags */}
      <meta name="twitter:creator" content={name} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      {/* End Twitter tags */}
    </Helmet>
  );
}
