import React from "react";
import { Box, ProjectContainerStyled, ProjectGrid, Title } from "./styled";
import Cards from "../../components/Cards";
import { SEO } from "../../components";
// import { ContactForm } from '../Form'

const ProjectContainer = () => {
  return (
    <React.Fragment>
      <SEO
        title={"Project Apinun Saartiam"}
        description={
          "Helmet project"
        }
        name={"Apinun Saartiam"}
        type={"artical"}
        image={"https://scontent.fbkk15-1.fna.fbcdn.net/v/t39.30808-6/402170141_6878469752229153_5328328015685210718_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=a5f93a&_nc_ohc=cV3JwMcPWBkQ7kNvgH8ouQx&_nc_ht=scontent.fbkk15-1.fna&oh=00_AYBG_rh3fcyFWUcCpgqpJ7k0HKVXCyWNV5x5qOoqXtT5Sw&oe=66800A48"}
      />
      <ProjectContainerStyled>
        <Box>
          <Title>Project Showcase</Title>
        </Box>
        <Box>
          <ProjectGrid>
            <React.Suspense fallback={<div>Loading</div>}>
              <Cards.MyWork
                // isHover
                src={require(`../../assets/projects/02.PNG`)}
              />
              <Cards.MyWork
                // isHover
                src={require(`../../assets/projects/pho.png`)}
              />
              <Cards.MyWork
                // isHover
                src={require(`../../assets/projects/licensePlateDetection.jpg`)}
              />
              <Cards.MyWork
                // isHover
                src={require(`../../assets/projects/nodeWithJWT.png`)}
              />
            </React.Suspense>
          </ProjectGrid>
        </Box>
        <Box
          style={{
            backgroundColor: "#323232",
            paddingBottom: "16px",
            paddingTop: "16px",
          }}
        >
          {/* <ContactForm/> */}
        </Box>
      </ProjectContainerStyled>
    </React.Fragment>
  );
};

export default ProjectContainer;
