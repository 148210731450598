import React, { useEffect, useState } from "react";
import { Header, Links, LinksMenu, NavbarContainerStyled } from "./styled";
import { RxHamburgerMenu } from "react-icons/rx";
import { Box } from "./styled";
import {useNavigate} from 'react-router-dom'
import { ROUTE_PATH } from "../../path";
const Navbar = () => {

  const navigate = useNavigate()

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <NavbarContainerStyled>
      <Box>
        <Header onClick={()=>{navigate(ROUTE_PATH.MAIN)}}>MyPortfolio</Header>
        {screenWidth <= 900 ? (
          <React.Fragment>
            <RxHamburgerMenu
              color="#fff"
              size={20}
              onClick={() => setIsOpen(!isOpen)}
            />
          </React.Fragment>
        ) : (
          <LinksMenu>
            <Links  onClick={()=>{navigate(ROUTE_PATH.PROJECTSHOWCASE)}}>Overview</Links>
            {/* <Links onClick={()=>{navigate(ROUTE_PATH.ABOUT_ME)}}>About</Links> */}
            {/* <Links>Contact</Links> */}
          </LinksMenu>
        )}
      </Box>
      <Box
        style={{
          flexDirection: "column",
          padding: "10px",
          gap: "10px",
          // backgroundColor: "#FFFFFF",
        }}
      >
        {isOpen && (
          <React.Fragment>
            <Links onClick={()=>{navigate(ROUTE_PATH.PROJECTSHOWCASE)}}>Overview</Links>
            {/* <Links onClick={()=>{navigate(ROUTE_PATH.ABOUT_ME)}}>About</Links> */}
            {/* <Links>Contact</Links> */}
          </React.Fragment>
        )}
      </Box>
    </NavbarContainerStyled>
  );
};

export default Navbar;
