import styled from "styled-components";

export const NavbarContainerStyled = styled.div`
  z-index: 9999;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #030303;
  padding: 15px 24px 15px 24px;
  @media screen and (max-width: 670px) {
    /* flex-direction: column; */
  }
`;
export const Box = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
`;
export const LinksMenu = styled.div`
  display: flex;
  flex: 0.3;
  gap: 14px;
  justify-content: flex-end;
`;
export const Header = styled.div`
  display: flex;
  /* flex: 1; */
  /* background-color: red; */
  font-size: 20px;
  font-family: "Poppins-R", sans-serif;
  color: #fff;
  cursor: pointer;
  :hover {
    color: #ffffff;
    opacity: 0.85;
  }
`;

export const Hamburger = styled.div``;

export const Links = styled.div`
  position: relative;
  font-size: 18px;
  font-weight: 400;
  font-family: "Poppins-L", sans-serif;
  color: #fff;
  cursor: pointer;
  :after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: #fff;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }
  :hover:after {
    width: 100%;
    left: 0;
    opacity: 0.85;
  }
`;
export const Text = styled.div``;
