import styled from "styled-components";

export const MyWorkContainer = styled.div`
  z-index: 1;
  display: flex;
  flex: 1;
  object-fit: cover;
  position: relative;
  flex-direction: column;
`;
export const BTNContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex: 1;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  opacity: 0;
  :hover {
    display: flex;
    background-color: black;
    opacity: 0.75;
  }
`;
export const TitleText = styled.div`
  font-size: 24px;
  padding: 16px;
  font-family: "Poppins-R", sans-serif;
  color: #ffffff;
`;
