import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
// import App from "../App";
import { Home } from "../pages";
import MainLayout from "../layout/mainLayout";
import ProjectContainer from "../pages/Project";
import AboutMe from "../pages/About";

export const ROUTE_PATH = {
  MAIN: "/",
  HOME: "/home",
  PROJECTSHOWCASE: "/project",
  PROJECTSHOWCASE_DETAILS: "/project/id",
  ABOUT_ME: "/about",
};

export const route = createBrowserRouter(
  createRoutesFromElements(
    <Route path={ROUTE_PATH.MAIN} element={<MainLayout />}>
      <Route index element={<Home />} />
      <Route path={ROUTE_PATH.PROJECTSHOWCASE} element={<ProjectContainer />} />
      <Route path={ROUTE_PATH.ABOUT_ME} element={<AboutMe />} />
    </Route>
  )
);
