import React from "react";
import {
  Box,
  CountDes,
  CountTitle,
  GridBox,
  HeaderBox,
  HomeContainerStyled,
  LeftHeader,
  RightHeader,
  Title,
  TitleSmall,
} from "./styled";
import { Images, SEO } from "../../components";
import Cards from "../../components/Cards";
// import {STYLED} from '../../constant/styleConstant'
import { TypeAnimation } from "react-type-animation";

import mockImage from "../../assets/images/greeting.png";
// import { ContactForm } from "../Form";

const Home = () => {
  const count = [
    {
      name: "Projects Completed",
      count: "20+",
    },
    {
      name: "Years of Experience",
      count: "3+",
    },
    {
      name: "Certification",
      count: "6",
    },
    //         {
    // name:'Satisfied Clients',
    // count:'69'
    //         },
  ];

  const position = [
    // Same substring at the start will only be typed out once, initially
    "I AM A FULLSTACK DEVELOPER",
    2000, // wait 1s before replacing "Mice" with "Hamsters"
    "I AM A SOFTWARE DEVELOPER",
    2000,
    "I AM A MOBILE DEVELOPER",
    2000,
  ];
  const textGreeting = [
    // Same substring at the start will only be typed out once, initially
    "HI EVREY ONE !",
    2000, // wait 1s before replacing "Mice" with "Hamsters"
    "WELCOME TO MY OFFICIAL WEBSITE",
    2000,
    "LIVING IN THAILAND",
    2000,
  ];

  return (
    <React.Fragment>
      <SEO
        title={"Apinun DEV"}
        description={
          "Apinun Saartiam homepage Website for a comprehensive view of my skills, achievements, and experiences. Discover my expertise in Software Developer, and learn how I can contribute to your projects. Get insights into my professional journey and find out what sets me apart. Connect with me to explore collaboration opportunities and stay updated on my latest endeavors."
        }
        name={"Apinun Saartiam"}
        type={"website"}
        image={"https://scontent.fbkk15-1.fna.fbcdn.net/v/t39.30808-6/352061994_778233200629188_8296419549862492810_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=a5f93a&_nc_ohc=I_QmAbLoz0wQ7kNvgHKhhBA&_nc_ht=scontent.fbkk15-1.fna&oh=00_AYBBK33JbEUeZcTcumlLH2Z87wowzC1iA2KSzOlD4JTNxg&oe=66800614"}
      />
      <HomeContainerStyled>
        <Box>
          <TypeAnimation
            sequence={textGreeting}
            wrapper="span"
            speed={50}
            style={{
              fontWeight: "400",
              fontSize: "24px",
              display: "inline-block",
              fontFamily: "Poppins-R, sans-serif",
            }}
            repeat={Infinity}
          />
        </Box>
        <Box>
          <HeaderBox>
            <LeftHeader>
              <TypeAnimation
                sequence={position}
                wrapper="span"
                speed={50}
                style={{
                  fontWeight: "500",
                  fontSize: "50px",
                  display: "inline-block",
                  fontFamily: "Poppins-M, sans-serif",
                }}
                repeat={Infinity}
              />
              <Title>WITH EXPERTISE IN WEB AND APP DEVELOPMENT.</Title>
            </LeftHeader>
            <RightHeader>
              <img
                alt="apinun_saartiam"
                src="https://www.dropbox.com/scl/fi/mvi5z3m804punvzoovv3y/unnamed.jpg?rlkey=1xpe61vrdfjd8zd0g6arq2x1c&st=7wd982s6&dl=0"
                style={{
                  borderRadius: "100rem",
                  objectFit: "cover",
                  // objectFit:'scale-down',
                  width: "50%",
                  // height:'100%'
                }}
              />
              <TitleSmall>
                Welcome to my professional portfolio. I specialize in fullstack
                development and I'm passionate about creating innovative web and
                app solutions. Explore my work and feel free to get in touch.
              </TitleSmall>
            </RightHeader>
          </HeaderBox>
        </Box>
        <Box>
          <Images src={mockImage} alt={`${mockImage}`} width={"100%"} />
        </Box>
        <Box>
          {count.map((item,index) => (
            <Box
              key={index}
              style={{
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CountTitle>{item.count}</CountTitle>
              <CountDes>{item.name}</CountDes>
            </Box>
          ))}
        </Box>
        <Box>{/* Service */}</Box>
        <Box>
          {/* MyWork */}
          <GridBox>
            <Cards.MyWork
              // isHover
              alt={'youtube clone Project'}
              src={require(`../../assets/projects/02.PNG`)}
            />
            <Cards.MyWork
              // isHover
              alt={'project image'}
              src={require(`../../assets/projects/pho.png`)}
            />
            {/* <Cards.MyWork
              // isHover
              src={require(`../../assets/projects/licensePlateDetection.jpg`)}
            />
            <Cards.MyWork
              // isHover
              src={require(`../../assets/projects/nodeWithJWT.png`)}
            /> */}
            {/* <Cards.MyWork isHover /> */}
          </GridBox>
        </Box>
        <Box
          style={{
            backgroundColor: "#323232",
            paddingBottom: "16px",
            paddingTop: "16px",
          }}
        >
          {/* <ContactForm onSubmit={() => console.log("Hello")} /> */}
        </Box>
      </HomeContainerStyled>
    </React.Fragment>
  );
};

export default Home;
