import React from 'react'
import { ButtonContainer, ButtonTittle } from './styled'

const Buttons = ({title = 'Send',customText,isOutline,onClick,...props}) => {

    const customStyled = (props) => {
        return {
            borderRadius: `${props.borderRadius ? props.borderRadius:'8px'}`,
            backgroundColor: props?.color ? props?.color : '#323232',
            ...props?.customStyle
        }
    }
    const customOutlineStyled = (props) => {
        return {
            border: `${props.borderColor ? props.borderColor : '#ffffff'} ${props.borderSize ? props.borderSize: '1px'} solid`,
            borderRadius: `${props.borderRadius ? props.borderRadius:'8px'}`,
            backgroundColor: props?.color ? props?.color : '#323232',
            ...props?.customStyle
        }
    }
    const customTextStyled = (props) => {
        return {
            fontSize:` ${ props.fontSize ? props.fontSize: '18px'}`,
            fontFamily: `${props.fontFamily ? props.fontFamily : "Poppins-R" }, sans-serif`,
            color: props?.textColor ? props?.textColor : '#ffffff',
            ...customText
        }
    }

    
    return(
        <ButtonContainer onClick={onClick} style={ isOutline ? customOutlineStyled(props) : customStyled(props)}>
            <ButtonTittle style={customTextStyled(props)}>{title}</ButtonTittle>
        </ButtonContainer>
    )

}

export default Buttons