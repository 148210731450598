import React from 'react'

const Images = ({
    alt,
    src,
    width,
    height
}) =>{


    return(
        <img alt={alt} src={src} width={width} height={height}/>
    )
}

export default Images