import styled from "styled-components";

export const MainContainerStyled = styled.div`
  width: 100%;
  padding-top: 60px;
  background-color: #030303;
  font-family: "Poppins-R", sans-serif;
`;
export const FooterContainer = styled.div`
  font-size: 18px;
  padding: 16px;
  font-family: "Poppins-R", sans-serif;
  background-color: #323232;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 12px;
`;
