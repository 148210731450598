import React from "react";
import { FooterContainer, MainContainerStyled } from "./styled";
import { Navbar } from "../components";
import { Outlet } from "react-router-dom";
import {
  FaLinkedin,
  // FaInstagram
} from "react-icons/fa";
import { AiFillGithub } from "react-icons/ai";

const MainLayout = (props) => {
  return (
    <MainContainerStyled>
      <Navbar />
      <Outlet />
      <FooterContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            alignItems: "flex-end",
          }}
        >
          <FaLinkedin
            size={30}
            onClick={() =>
              window.open(
                "https://www.linkedin.com/in/apinun-saartiam-5b388a277/"
              )
            }
          />
          <AiFillGithub
            size={30}
            onClick={() => window.open("https://github.com/lostperson7410")}
          />
        </div>
        © 2024 MyPortfolio. All Rights Reserved.
      </FooterContainer>
    </MainContainerStyled>
  );
};

export default MainLayout;
