import styled from "styled-components";

export const ButtonContainer = styled.div`
  width: 100%;
  padding: 8px;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  :active {
    opacity: 0.85;
  }
`;
export const ButtonTittle = styled.div`
  color: #fff;
  font-size: 16px;
  font-family: "Poppins-R", sans-serif;
`;
