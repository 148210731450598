import styled from "styled-components";

export const AboutMeContainerStyled = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  flex: 1;
  color: #fff;
  width: 100%;
  min-height: 100dvh;
  gap: 48px;
`;
