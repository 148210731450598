import styled from "styled-components";

export const ProjectContainerStyled = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  flex: 1;
  color: #fff;
  width: 100%;
  min-height: 100dvh;
  gap: 48px;
`;
export const Box = styled.div`
  padding: 0px 24px 0px 24px;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  /* background-color: aliceblue; */
`;
export const Title = styled.div`
  font-size: 48px;
  font-family: "Poppins-M", sans-serif;
  @media screen and (max-width: 670px) {
    font-size: 24px;
  }
  @media screen and (max-width: 380px) {
    font-size: 24px;
  }
`;
export const ProjectGrid = styled.div`
  width: 100%;
  gap: 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(1, 1fr);
  /* background-color: gainsboro; */
  @media screen and (max-width: 670px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
