import styled from "styled-components";

export const HomeContainerStyled = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  flex: 1;
  color: #fff;
  /* background-color: red; */
  width: 100%;
  min-height: 100dvh;
  gap: 48px;
`;
export const Box = styled.div`
  padding: 0px 24px 0px 24px;
  display: flex;
  /* background-color: blue; */
  flex: 1;
  flex-wrap: wrap;
`;
export const HeaderBox = styled.div`
  display: flex;
  flex: 1;
  gap: 48px;
  @media screen and (max-width: 900px) {
    flex-wrap: wrap;
    flex-direction: column;
  }
`;
export const Title = styled.div`
  font-size: 50px;
  font-family: "Poppins-M", sans-serif;
  @media screen and (max-width: 670px) {
    font-size: 48px;
  }
  @media screen and (max-width: 380px) {
    font-size: 24px;
  }
`;
export const TitleSmall = styled.div`
  font-size: 18px;
  font-weight: 100;
  font-family: "Poppins-L", sans-serif;
`;
export const LeftHeader = styled.div``;
export const RightHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;
export const CountTitle = styled.div`
  font-size: 48px;
  font-weight: 600;
  font-family: "Poppins-R", sans-serif;
`;
export const CountDes = styled.div`
  font-size: 20px;
  font-family: "Poppins-L", sans-serif;
`;

export const GridBox = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  gap: 10px;
`;
