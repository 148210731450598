import React from 'react'
import { AboutMeContainerStyled } from './styled'

const AboutMe = () =>{


    return(
        <AboutMeContainerStyled>
            <div>Banner</div>
            <div>Banner</div>
            <div>Banner</div>

        </AboutMeContainerStyled>
    )
}


export default AboutMe