import React from "react";
import { BTNContainer, MyWorkContainer, TitleText } from "./styed";
import img from "../../../assets/images/mockProject.png";
import Buttons from "../../Buttons/Button";
// import { STYLED } from "../../../constant/styleConstant";
const MyWork = ({ onClick,title,src,path,filename,alt,isHover, ...props }) => {
  return (
    <MyWorkContainer>
      {
        isHover &&
          <BTNContainer>
            <Buttons
              onClick={onClick}
              title="View Details"
              color={"#9E4A50"}
              customStyle={{
                width: "30%",
              }}
            />
          </BTNContainer>
      }
      <img alt={alt} src={
        src || img}
        style={
          {
            maxWidth:'100%',
            maxHeight:'500px',
            objectFit:'contain',
            aspectRatio: 16/9,
          }
        } />
      {
        title && 
      <TitleText>
        {title}
      </TitleText>
      }
    </MyWorkContainer>
  );
};

export default MyWork;
